<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-lg-9 col-12 mx-auto">
                <div class="card">
                    <div class="card-header p-3 pb-0">
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div>
                                <h6>ตะกร้าสินค้า</h6>
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-3 pt-0">
                        <hr class="horizontal dark mt-0 mb-2" />
                        <div class="row">
                            <div class="col-12 col-sm-6 px-md-4">
                                <div
                                    class="row mb-2"
                                    v-for="(item, index) in cartData"
                                    :key="index"
                                >
                                    <div class="col-12">
                                        <div class="d-flex">
                                            <router-link
                                                :to="`/product/${item.id}`"
                                            >
                                                <argon-avatar
                                                    :img="imagePath + item.img"
                                                    class="me-3 mt-3"
                                                    size="xl"
                                                    border-radius="lg"
                                                    :alt="item.name"
                                                />
                                            </router-link>
                                            <div class="w-100">
                                                <router-link
                                                    :to="`/product/${item.id}`"
                                                    class="font-weight-bold text-dark mb-0 mt-2"
                                                >
                                                    {{ item.name }}
                                                </router-link>
                                                <div
                                                    class="d-flex justify-content-between align-items-center"
                                                >
                                                    <div
                                                        class="text-xs"
                                                        style="width: 140px"
                                                    >
                                                        ตัวเลือก :
                                                        {{ item.options }}
                                                        <span
                                                            v-if="
                                                                item.pair !==
                                                                'ไม่มี'
                                                            "
                                                            >+
                                                            {{
                                                                item.pair
                                                            }}</span
                                                        >
                                                    </div>
                                                    <div>
                                                        <i
                                                            class="fas fa-minus"
                                                            @click="
                                                                handleCartProductQuantity(
                                                                    index,
                                                                    item.qty,
                                                                    'minus'
                                                                )
                                                            "
                                                        ></i>
                                                        <span class="mx-2">{{
                                                            item.qty
                                                        }}</span>
                                                        <i
                                                            class="fas fa-plus"
                                                            @click="
                                                                handleCartProductQuantity(
                                                                    index,
                                                                    item.qty,
                                                                    'plus'
                                                                )
                                                            "
                                                        ></i>
                                                    </div>
                                                </div>
                                                <div class="d-flex">
                                                    <div
                                                        class="text-sm font-weight-bold"
                                                    >
                                                        ฿{{
                                                            item.price +
                                                            item.pair_price *
                                                                item.pair_qty
                                                        }}
                                                    </div>
                                                </div>

                                                <div
                                                    class="d-flex justify-content-between align-items-center"
                                                >
                                                    <argon-badge
                                                        color="success"
                                                        variant="gradient"
                                                        size="sm"
                                                        class="mt-1"
                                                        >มีสินค้า</argon-badge
                                                    >
                                                    <i
                                                        class="fas fa-trash-alt"
                                                        @click="
                                                            removeCartProduct(
                                                                index
                                                            )
                                                        "
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 px-md-4">
                                <div class="col-12">
                                    <h6 class="mb-3 mt-4">ข้อมูลการจัดส่ง</h6>

                                    <label>
                                        <span
                                            class="badge bg-warning"
                                            v-if="shop != 'deli'"
                                        >
                                            โลเคชั่น
                                            จะส่งตามจุดที่ลูกค้าอยู่ปัจจุบัน
                                        </span>
                                    </label>
                                    <div class="form-group">
                                        <label for="exampleFormControlInput1"
                                            ><span class="text-danger">*</span>
                                            เบอร์โทรศัพท์</label
                                        >
                                        <input
                                            type="phone"
                                            class="form-control"
                                            placeholder="0829394XXX"
                                            v-model="phone"
                                            maxlength="10"
                                        />
                                        <div v-if="shop !== 'deli'">
                                            <label
                                                for="exampleFormControlTextarea1"
                                                ><span class="text-danger"
                                                    >*</span
                                                >
                                                ชื่อหอพัก/หมู่บ้าน/สถานที่</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="ชื่อหอพัก/หมู่บ้าน/สถานที่"
                                                v-model="position"
                                            />
                                        </div>
                                        <div v-if="shop === 'deli'">
                                            <label
                                                for="exampleFormControlTextarea1"
                                                ><span class="text-danger"
                                                    >*</span
                                                >
                                                ชื่อ - นามสกุล</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="ชื่อ - นามสกุล"
                                                v-model="name"
                                            />
                                        </div>
                                        <div v-if="shop === 'deli'">
                                            <div class="row">
                                                <div class="col-6">
                                                    <div>
                                                        <label
                                                            for="exampleFormControlTextarea1"
                                                            ><span
                                                                class="text-danger"
                                                                >*</span
                                                            >
                                                            บ้านเลขที่</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="บ้านเลขที่"
                                                            v-model="
                                                                address.homeNumber
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div>
                                                        <label
                                                            for="exampleFormControlTextarea1"
                                                            >หมู่</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="หมู่"
                                                            v-model="
                                                                address.villageNumber
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <div>
                                                        <label
                                                            for="exampleFormControlTextarea1"
                                                            ><span
                                                                class="text-danger"
                                                                >*</span
                                                            >
                                                            ตำบล</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="ตำบล"
                                                            v-model="
                                                                address.subdistrict
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div>
                                                        <label
                                                            for="exampleFormControlTextarea1"
                                                            ><span
                                                                class="text-danger"
                                                                >*</span
                                                            >
                                                            อำเภอ</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="อำเภอ"
                                                            v-model="
                                                                address.district
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <div>
                                                        <label
                                                            for="exampleFormControlTextarea1"
                                                            ><span
                                                                class="text-danger"
                                                                >*</span
                                                            >
                                                            จังหวัด</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="จังหวัด"
                                                            v-model="
                                                                address.city
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div>
                                                        <label
                                                            for="exampleFormControlTextarea1"
                                                            ><span
                                                                class="text-danger"
                                                                >*</span
                                                            >
                                                            รหัสไปรษณีย์</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="รหัสไปรษณีย์"
                                                            v-model="
                                                                address.zipcode
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <label
                                            ><span class="text-danger">*</span>
                                            ช่องทางชำระเงิน</label
                                        >
                                        <select
                                            class="form-control"
                                            v-if="shop === 'deli'"
                                            v-model="payment"
                                        >
                                            <option value="">เลือก...</option>
                                            <option value="ชำระแบบโอน">
                                                ชำระแบบโอน
                                            </option>
                                        </select>
                                        <select
                                            class="form-control"
                                            v-if="shop != 'deli'"
                                            v-model="payment"
                                        >
                                            <option value="">เลือก...</option>
                                            <option value="ชำระปลายทาง">
                                                ชำระปลายทาง
                                            </option>
                                        </select>
                                        <div
                                            class="form-check mt-3"
                                            v-if="payment === 'ชำระปลายทาง'"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                name="RadioPayment"
                                                id="RadioPayment1"
                                                v-model="payCashOn"
                                                value="โอนปลายทาง"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="RadioPayment1"
                                            >
                                                โอนปลายทาง
                                            </label>
                                        </div>
                                        <div
                                            class="form-check"
                                            v-if="payment === 'ชำระปลายทาง'"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                name="RadioPayment"
                                                id="RadioPayment2"
                                                v-model="payCashOn"
                                                value="เงินสดปลายทาง"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="RadioPayment2"
                                            >
                                                เงินสดปลายทาง
                                            </label>
                                        </div>
                                        <div
                                            class="d-flex justify-content-center mt-3"
                                        >
                                            <div
                                                class="badge bg-primary text-start w-100"
                                                style="font-size: 10px"
                                                v-if="shop === 'deli'"
                                            >
                                                ● ระยะเวลาในการจัดส่งสินค้า 1-2
                                                วันทำการ
                                            </div>
                                            <div
                                                class="badge bg-primary text-start w-100"
                                                style="font-size: 9px"
                                                v-if="shop != 'deli'"
                                            >
                                                ● ระยะเวลาในการจัดส่งสินค้า
                                                เริ่มต้นที่ 20 นาที
                                                ขึ้นอยู่กับระยะทางในการจัดส่ง
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 ms-auto">
                                    <h6 class="mb-3">สรุปรายการ</h6>
                                    <div class="d-flex justify-content-between">
                                        <span class="mb-2 text-sm"
                                            >ค่าสินค้า:</span
                                        >
                                        <span class="text-dark ms-2">
                                            {{ priceData?.products }}
                                        </span>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <span class="mb-2 text-sm"
                                            >ส่วนลด:</span
                                        >
                                        <div class="text-dark ms-2">
                                            <span>
                                                {{ priceData?.discount }}
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex justify-content-between"
                                        v-if="
                                            shop === 'deli' &&
                                            cartData.length != 0
                                        "
                                    >
                                        <span class="mb-2 text-sm"
                                            >ค่าจัดส่ง:</span
                                        >
                                        <span class="text-dark ms-2">{{
                                            deliPrice
                                        }}</span>
                                    </div>
                                    <div
                                        class="d-flex justify-content-between mt-4"
                                        v-if="cartData.length != 0"
                                    >
                                        <span class="mb-2 text-lg">รวม:</span>
                                        <span
                                            class="text-dark text-lg ms-2 font-weight-bold"
                                            >฿{{
                                                shop === "deli"
                                                    ? priceData?.total +
                                                      deliPrice
                                                    : priceData?.total
                                            }}</span
                                        >
                                    </div>
                                    <div class="text-end mt-3">
                                        <argon-button
                                            color="primary"
                                            variant="gradient"
                                            class="mb-0"
                                            @click="submitOrder"
                                            >ยืนยัน</argon-button
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="horizontal dark" />
                        <div class="row"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import axios from "../services/axios";
import { mapGetters } from "vuex";
import liff from "@line/liff";

export default {
    name: "OrderDetails",
    components: {
        ArgonAvatar,
        ArgonBadge,
        ArgonButton,
    },
    data() {
        return {
            loadingSubmit: false,
            cartData: [],
            priceData: [],
            payment: "",
            name: "",
            payCashOn: "",
            phone: "",
            deliPrice: 40,
            address: {
                homeNumber: "",
                villageNumber: "",
                subdistrict: "",
                district: "",
                city: "",
                zipcode: "",
            },
            locate: "",
            text: "",
            position: "",
            imagePath: process.env.VUE_APP_IMAGE_PATH,
            shop: "",
        };
    },
    created() {
        this.shop = localStorage.getItem("station");

        this.fetchCartData();
    },
    methods: {
        async submitOrder() {
            if (this.cartData.length !== 0) {
                if (!this.loadingSubmit) {
                    this.loadingSubmit = true;
                    //
                    // ส่งผ่านไรเดอร์
                    //
                    if (
                        this.shop !== "deli" &&
                        this.phone &&
                        this.payment &&
                        this.position
                    ) {
                        let locate;
                        if (this.shop === "loei") {
                            locate = "อ.เมือง";
                        } else if (this.shop === "wang") {
                            locate = "อ.วังสะพุง";
                        } else {
                            locate = "อ.เชียงคาน";
                        }

                        if (this.payment === "ชำระปลายทาง" && !this.payCashOn) {
                            this.$swal({
                                text: "เลือกการชำระเงิน",
                                icon: "error",
                            });
                            this.loadingSubmit = false;
                            return;
                        }
                        if (!isNaN(this.phone) && this.phone.length === 10) {
                            let paymentText =
                                this.payment === "ชำระปลายทาง"
                                    ? this.payCashOn
                                    : this.payment;
                            this.text =
                                "ส่งผ่านไรเดอร์ (" +
                                locate +
                                ") \nสถานที่ส่ง: " +
                                this.position +
                                "\nโซน: \n\n" +
                                "เบอร์โทรศัพท์: " +
                                this.phone +
                                "\n";

                            let allQuantity = 0;
                            let productList = [];

                            await Promise.all(
                                this.cartData.map(async (item) => {
                                    allQuantity += item.qty;
                                    const text = await this.setText(
                                        item.options,
                                        item.name,
                                        item.qty,
                                        item.price,
                                        item.pair_price,
                                        item.pair,
                                        item.pair_qty
                                    );
                                    productList.push(
                                        await this.setText(
                                            item.options,
                                            item.name,
                                            item.qty,
                                            item.price,
                                            item.pair_price,
                                            item.pair,
                                            item.pair_qty
                                        )
                                    );
                                    this.text += text;
                                })
                            );
                            this.text +=
                                "\n\nจำนวนสินค้า: " +
                                allQuantity +
                                " ชิ้น\n" +
                                (this.priceData.discount != 0
                                    ? "ยอดส่วนลด: " +
                                      this.priceData.discount +
                                      "฿\n"
                                    : "") +
                                "ยอดสินค้า: " +
                                this.priceData.total +
                                "฿\n" +
                                "ยอดค่าส่ง: \n\nรวมทั้งหมด: \nการชำระเงิน: " +
                                paymentText +
                                "\n\n**รอแอดมินคำนวณค่าจัดส่งสักครู่ครับ";

                            axios
                                .post("/order", {
                                    type: "ส่งผ่านไรเดอร์",
                                    phoneNumber: this.phone,
                                    total: allQuantity,
                                    payment: paymentText,
                                    price: this.priceData.total,
                                    discount: this.priceData.discount,
                                    place: this.position,
                                    address: null,
                                    branch: locate,
                                    productList,
                                })
                                .then(async (resp) => {
                                    if (resp?.data?.status === "success") {
                                        liff.sendMessages([
                                            {
                                                type: "flex",
                                                altText: `คำสั่งซื้อส่งผ่านไรเดอร์ (${locate})`,
                                                contents: {
                                                    type: "bubble",
                                                    size: "hecto",
                                                    body: {
                                                        type: "box",
                                                        layout: "vertical",
                                                        contents: [
                                                            {
                                                                type: "text",
                                                                text: `ส่งผ่านไรเดอร์ (${locate})`,
                                                                align: "center",
                                                            },
                                                            {
                                                                type: "button",
                                                                action: {
                                                                    type: "uri",
                                                                    label: "ดูรายการสั่งซื้อ",
                                                                    uri: `https://bbmshop.one/order/${resp?.data?.order}`,
                                                                },
                                                                style: "primary",
                                                                height: "sm",
                                                                margin: "md",
                                                                color: "#00ABFF",
                                                            },
                                                            {
                                                                type: "text",
                                                                text: "รอแอดมินคำนวณค่าจัดส่งสักครู่",
                                                                size: "xs",
                                                                color: "#FF8C00",
                                                                align: "center",
                                                                margin: "md",
                                                            },
                                                        ],
                                                    },
                                                },
                                            },
                                        ])
                                            .then(() => {
                                                const getPositionSuccess = (
                                                    position
                                                ) => {
                                                    const latitude =
                                                        position.coords
                                                            .latitude;
                                                    const longitude =
                                                        position.coords
                                                            .longitude;

                                                    liff.sendMessages([
                                                        {
                                                            type: "location",
                                                            title: "My Location",
                                                            address:
                                                                "ที่อยู่ในการจัดส่ง",
                                                            latitude: latitude,
                                                            longitude:
                                                                longitude,
                                                        },
                                                    ])
                                                        .then(() => {
                                                            localStorage.removeItem(
                                                                "cart"
                                                            );
                                                            this.loadingSubmit = false;
                                                            liff.closeWindow();
                                                        })
                                                        .catch(() => {
                                                            this.loadingSubmit = false;
                                                            liff.closeWindow();
                                                        });
                                                };

                                                const getPositionError = () => {
                                                    localStorage.removeItem(
                                                        "cart"
                                                    );
                                                    this.loadingSubmit = false;
                                                    liff.closeWindow();
                                                };

                                                navigator.geolocation.getCurrentPosition(
                                                    getPositionSuccess,
                                                    getPositionError
                                                );
                                            })
                                            .catch(() => {
                                                alert(
                                                    "ไม่สามารถสั่งซื้อได้ กรุณาลองใหม่"
                                                );
                                                this.loadingSubmit = false;
                                                liff.closeWindow();
                                            });
                                    } else {
                                        this.$swal({
                                            text: "ไม่สามารถทำรายการได้ กรุณาลองใหม่",
                                            icon: "error",
                                        });
                                    }
                                })
                                .catch((err) => {
                                    if (err?.response) {
                                        this.$swal({
                                            text: err?.response?.data?.message
                                                ? err?.response?.data?.message
                                                : "ไม่สามารถทำรายการได้ กรุณาลองใหม่",
                                            icon: "error",
                                        });
                                    }
                                });
                        } else {
                            this.$swal({
                                text: "เบอร์โทรศัพท์ไม่ถูกต้อง",
                                icon: "error",
                            });
                            this.loadingSubmit = false;
                        }
                        //
                        // ส่งพัสดุ
                        //
                    } else if (
                        this.shop === "deli" &&
                        this.phone &&
                        this.payment &&
                        this.name &&
                        this.address.homeNumber &&
                        this.address.subdistrict &&
                        this.address.district &&
                        this.address.city &&
                        this.address.zipcode
                    ) {
                        if (!isNaN(this.phone) && this.phone.length === 10) {
                            let addressFormat = "";
                            if (this.address.villageNumber) {
                                addressFormat =
                                    this.name +
                                    "\n" +
                                    this.phone +
                                    "\n" +
                                    this.address.homeNumber +
                                    " ม." +
                                    this.address.villageNumber +
                                    " ต." +
                                    this.address.subdistrict +
                                    " อ." +
                                    this.address.district +
                                    " จ." +
                                    this.address.city +
                                    " " +
                                    this.address.zipcode;
                            } else {
                                addressFormat =
                                    this.name +
                                    "\n" +
                                    this.phone +
                                    "\n" +
                                    this.address.homeNumber +
                                    " ต." +
                                    this.address.subdistrict +
                                    " อ." +
                                    this.address.district +
                                    " จ." +
                                    this.address.city +
                                    " " +
                                    this.address.zipcode;
                            }
                            this.text =
                                "ส่งผ่านพัสดุ \n\n" +
                                "ที่อยู่:\n" +
                                addressFormat +
                                "\n";

                            let allQuantity = 0;
                            let productList = [];

                            await Promise.all(
                                this.cartData.map(async (item) => {
                                    allQuantity += item.qty;
                                    const text = await this.setText(
                                        item.options,
                                        item.name,
                                        item.qty,
                                        item.price,
                                        item.pair_price,
                                        item.pair,
                                        item.pair_qty
                                    );
                                    productList.push(
                                        await this.setText(
                                            item.options,
                                            item.name,
                                            item.qty,
                                            item.price,
                                            item.pair_price,
                                            item.pair,
                                            item.pair_qty
                                        )
                                    );
                                    this.text += text;
                                })
                            );

                            this.text +=
                                "\n\nจำนวนสินค้า: " +
                                allQuantity +
                                " ชิ้น\n" +
                                (this.priceData.discount != 0
                                    ? "ยอดส่วนลด: " +
                                      this.priceData.discount +
                                      "฿\n"
                                    : "") +
                                "ยอดสินค้า: " +
                                this.priceData.total +
                                "฿\nยอดค่าส่ง: " +
                                this.deliPrice +
                                "฿\n\nรวมทั้งหมด: " +
                                (this.priceData.total + this.deliPrice) +
                                "฿\nการชำระเงิน: " +
                                this.payment;

                            axios
                                .post("/order", {
                                    type: "ส่งผ่านพัสดุ",
                                    phoneNumber: this.phone,
                                    total: allQuantity,
                                    payment: this.payment,
                                    price: this.priceData.total,
                                    discount: this.priceData.discount,
                                    place: null,
                                    address: addressFormat,
                                    branch: null,
                                    productList,
                                })
                                .then(async (resp) => {
                                    if (resp?.data?.status === "success") {
                                        liff.sendMessages([
                                            {
                                                type: "flex",
                                                altText:
                                                    "คำสั่งซื้อส่งผ่านพัสดุ",
                                                contents: {
                                                    type: "bubble",
                                                    size: "hecto",
                                                    body: {
                                                        type: "box",
                                                        layout: "vertical",
                                                        contents: [
                                                            {
                                                                type: "text",
                                                                text: `ส่งผ่านพัสดุ`,
                                                                align: "center",
                                                            },
                                                            {
                                                                type: "button",
                                                                action: {
                                                                    type: "uri",
                                                                    label: "ดูรายการสั่งซื้อ",
                                                                    uri: `https://bbmshop.one/order/${resp?.data?.order}`,
                                                                },
                                                                style: "primary",
                                                                height: "sm",
                                                                margin: "md",
                                                                color: "#00ABFF",
                                                            },
                                                        ],
                                                    },
                                                },
                                            },
                                        ])
                                            .then(() => {
                                                localStorage.removeItem("cart");
                                                this.loadingSubmit = false;
                                                liff.closeWindow();
                                            })
                                            .catch(() => {
                                                alert(
                                                    "ไม่สามารถสั่งซื้อได้ กรุณาลองใหม่"
                                                );
                                                this.loadingSubmit = false;
                                                liff.closeWindow();
                                            });
                                        // liff.sendMessages([
                                        //     {
                                        //         type: "text",
                                        //         text: `ออเดอร์ ส่งผ่านพัสดุ\nhttps://bbmshop.one/order/${resp?.data?.order}`,
                                        //     },
                                        // ])
                                        //     .then(() => {
                                        //         localStorage.removeItem("cart");
                                        //         this.loadingSubmit = false;
                                        //         liff.closeWindow();
                                        //     })
                                        //     .catch((err) => {
                                        //         alert(err);
                                        //         console.log(err);
                                        //         this.loadingSubmit = false;
                                        //         liff.closeWindow();
                                        //     });
                                    } else {
                                        this.$swal({
                                            text: "ไม่สามารถทำรายการได้ กรุณาลองใหม่",
                                            icon: "error",
                                        });
                                    }
                                })
                                .catch((err) => {
                                    if (err?.response) {
                                        this.$swal({
                                            text: err?.response?.data?.message
                                                ? err?.response?.data?.message
                                                : "ไม่สามารถทำรายการได้ กรุณาลองใหม่",
                                            icon: "error",
                                        });
                                    }
                                });

                            // liff.sendMessages([
                            //     {
                            //         type: "text",
                            //         text: this.text,
                            //     },
                            // ])
                            //     .then(() => {
                            //         localStorage.removeItem("cart");
                            //         this.loadingSubmit = false;
                            //         liff.closeWindow();
                            //     })
                            //     .catch((err) => {
                            //         alert(err);
                            //         console.log(err);
                            //         this.loadingSubmit = false;
                            //     });

                            //     }
                            // })
                            // .catch((err) => {
                            //     if (err?.response) {
                            //         if (err?.response?.status !== 500) {
                            //             this.$swal({
                            //                 text: err?.response?.data?.message
                            //                     ? err?.response?.data?.message
                            //                     : "ไม่สามารถทำรายการได้ กรุณาลองใหม่",
                            //                 icon: "error",
                            //             });
                            //         }
                            //     }
                            // });
                        } else {
                            this.$swal({
                                text: "เบอร์โทรศัพท์ไม่ถูกต้อง",
                                icon: "error",
                            });
                            this.loadingSubmit = false;
                        }
                    } else {
                        this.$swal({
                            text: "กรอกข้อมูลการจัดส่งให้ครบถ้วน",
                            icon: "error",
                        });
                        this.loadingSubmit = false;
                    }
                }
            }
        },
        setText(options, name, quantity, price, pairPrice, pairName, pair_qty) {
            return new Promise((resolve) => {
                let optionText = options !== "ไม่มี" ? options : "";
                let pairText = pairName !== "ไม่มี" ? " + " + pairName : "";
                let priceText = (price + pairPrice * pair_qty) * quantity;
                let text =
                    // "\n" +
                    name +
                    " | " +
                    optionText +
                    pairText +
                    " x" +
                    quantity +
                    " = " +
                    priceText +
                    "฿";
                resolve(text);
            });
        },
        fetchCartData() {
            let shop = this.shop == "deli" ? "loei" : this.shop;
            const cart = JSON.parse(localStorage.getItem("cart"));

            if (cart) {
                axios
                    .post("/cart", {
                        cart: cart,
                        shop: shop,
                    })
                    .then(async (resp) => {
                        if (resp?.data?.status === "success") {
                            const data = resp?.data?.data?.products;
                            this.cartData = data;
                            this.priceData = resp?.data?.data?.price;

                            if (this.shop === "deli" && data.length > 0) {
                                let allQuantity = 0;

                                await Promise.all(
                                    data.map(async (item) => {
                                        allQuantity += item.qty;
                                    })
                                );

                                let calDeliPrice = allQuantity * 0.6;

                                if (calDeliPrice > 40) {
                                    this.deliPrice = Math.ceil(calDeliPrice);
                                }
                            }
                        }
                    })
                    .catch((err) => {
                        if (err?.response) {
                            console.log(err?.response?.data);
                        }
                    });
            }
        },
        handleCartProductQuantity(id, quantity, state) {
            let newQuantity;

            if (state === "minus") {
                if (quantity > 1) {
                    newQuantity = quantity - 1;
                } else {
                    return;
                }
            }
            if (state === "plus") {
                newQuantity = quantity + 1;
            }

            let cart = JSON.parse(localStorage.getItem("cart"));
            cart[id].qty = newQuantity;
            localStorage.setItem("cart", JSON.stringify(cart));

            this.fetchCartData();
        },
        removeCartProduct(id) {
            this.$swal({
                text: "ลบสินค้าในตะกร้า ?",
                showCancelButton: true,
                confirmButtonText: "ยืนยัน",
                cancelButtonText: "ยกเลิก",
                reverseButtons: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    let cart = JSON.parse(localStorage.getItem("cart"));
                    cart.splice(id, 1);
                    localStorage.setItem("cart", JSON.stringify(cart));

                    this.fetchCartData();
                }
            });
        },
    },
    computed: {
        ...mapGetters(["Profile"]),
    },
};
</script>

<style scoped>
.product_name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
