import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

export default createStore({
    state: {
        hideConfigButton: false,
        isPinned: true,
        showConfig: false,
        isRTL: false,
        color: "",
        sidebarType: "bg-white",
        darkMode: false,
        isNavFixed: false,
        isAbsolute: false,
        showNavs: true,
        showSidenav: true,
        showNavbar: true,
        showFooter: true,
        showMain: true,
        layout: "default",
        bootstrap,
        profile: {},
        page: "",
        previousCategory: "",
    },
    mutations: {
        saveProfile(state, profile) {
            state.profile = profile;
        },
        savePage(state, page) {
            state.page = page;
        },
        savePreviousCategory(state, previousCategory) {
            state.previousCategory = previousCategory;
        },
        toggleConfigurator(state) {
            state.showConfig = !state.showConfig;
        },
        navbarMinimize(state) {
            const sidenav_show = document.querySelector("#app");

            if (
                sidenav_show.classList.contains("g-sidenav-show") &&
                sidenav_show.classList.contains("g-sidenav-hidden")
            ) {
                sidenav_show.classList.add("g-sidenav-pinned");
                sidenav_show.classList.remove("g-sidenav-hidden");
                state.isPinned = true;
            } else if (
                sidenav_show.classList.contains("g-sidenav-show") &&
                sidenav_show.classList.contains("g-sidenav-pinned")
            ) {
                sidenav_show.classList.add("g-sidenav-hidden");
                sidenav_show.classList.remove("g-sidenav-pinned");
                state.isPinned = false;
            } else if (
                sidenav_show.classList.contains("g-sidenav-show") &&
                window.innerWidth < 1200
            ) {
                sidenav_show.classList.add("g-sidenav-pinned");
                state.isPinned = true;
            } else {
                sidenav_show.classList.add("g-sidenav-hidden");
                state.isPinned = true;
            }
        },
        setSidebarType(state, payload) {
            state.sidebarType = payload;
        },
        navbarFixed(state) {
            if (state.isNavFixed === false) {
                state.isNavFixed = true;
            } else {
                state.isNavFixed = false;
            }
        },
        toggleDefaultLayout(state) {
            state.showNavbar = !state.showNavbar;
            state.showSidenav = !state.showSidenav;
            state.showFooter = !state.showFooter;
        },
    },
    actions: {
        saveProfile(context, profile) {
            context.commit("saveProfile", profile);
        },
        savePage(context, page) {
            context.commit("savePage", page);
        },
        savePreviousCategory(context, previousCategory) {
            context.commit("savePreviousCategory", previousCategory);
        },
        toggleSidebarColor({ commit }, payload) {
            commit("setSidebarType", payload);
        },
    },
    getters: {
        Profile: (state) => {
            return state.profile;
        },
        Page: (state) => {
            return state.page;
        },
        PreviousCategory: (state) => {
            return state.previousCategory;
        },
    },
});
