<template>
    <div class="pb-4 container-fluid">
        <div class="row">
            <Boardcast />
        </div>
        <div class="row">
            <div class="col-md-3 col-12">
                <div class="card cate-card">
                    <!-- Card header -->
                    <div class="p-0 px-1 pt-2 card-header">
                        <div class="text-center">
                            <div>
                                <div class="font-weight-bold text-dark">
                                    หมวดหมู่
                                </div>
                            </div>
                        </div>
                        <hr class="horizontal dark" />
                    </div>
                    <div
                        class="p-0 card-body mb-2 d-flex justify-content-center"
                        v-if="category.length > 0"
                    >
                        <select
                            class="form-control w-90"
                            @change="resetLoadingData()"
                            v-model="selectCategory"
                            v-if="!messengerFirst"
                        >
                            <option value="all">ทั้งหมด</option>
                            <option value="sale">สินค้าลดราคา</option>
                            <option
                                v-for="(item, index) in category"
                                :key="index"
                                :value="item.name"
                            >
                                {{ item.name }}
                            </option>
                            <option value="messenger" v-if="checkMessenger">
                                เมสเซนเจอร์
                            </option>
                        </select>
                        <select
                            class="form-control w-90"
                            @change="resetLoadingData()"
                            v-model="selectCategory"
                            v-else
                        >
                            <option value="messenger">เมสเซนเจอร์</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-9 col-12 mt-md-0 mt-3">
                <div class="card">
                    <!-- Card header -->
                    <div class="pb-0 card-header">
                        <div
                            class="d-md-flex justify-content-between align-items-center w-100"
                        >
                            <div class="text-center text-md-start">
                                <h5 class="mb-0">
                                    {{
                                        selectCategory === "messenger"
                                            ? "เมสเซนเจอร์"
                                            : "สินค้า"
                                    }}
                                </h5>
                            </div>
                            <div
                                class="w-lg-25 w-sm-50 w-100 d-flex align-items-center mt-3 mt-md-0"
                                v-if="selectCategory !== 'messenger'"
                            >
                                <div class="input-group">
                                    <span class="input-group-text text-body">
                                        <i
                                            class="fas fa-search"
                                            aria-hidden="true"
                                        ></i>
                                    </span>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="ค้นหา..."
                                        v-model="search"
                                    />
                                    <argon-button @click="submitSearch"
                                        >ค้นหา</argon-button
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="px-0 pb-0 card-body"
                        v-if="selectCategory !== 'messenger'"
                    >
                        <div
                            class="row w-100 mx-auto g-3"
                            v-if="state === 'all'"
                        >
                            <div
                                class="col-lg-3 col-sm-4 col-6 position-relative"
                                v-for="item in products"
                                :key="item.id"
                            >
                                <router-link
                                    :to="`/product/${item.id}`"
                                    class="products_card card mb-3"
                                    :class="{ disabled: item.stock === 0 }"
                                >
                                    <div class="product-banner">
                                        <img
                                            src="../assets/new.png"
                                            :alt="item.name + ' New'"
                                            class="banner"
                                            v-if="item.new == 1"
                                        />
                                        <img
                                            src="../assets/sale.png"
                                            :alt="item.name + ' Sale'"
                                            class="banner"
                                            v-if="item.sale == 1"
                                        />
                                        <img
                                            src="../assets/hot.png"
                                            :alt="item.name + ' Hot'"
                                            class="banner"
                                            v-if="item.hot == 1"
                                        />
                                        <img
                                            src="../assets/update.png"
                                            :alt="item.name + ' Update'"
                                            class="banner"
                                            v-if="item.p_update == 1"
                                        />
                                    </div>
                                    <div class="h-100">
                                        <div
                                            class="card-header p-0 mx-3 mt-3 position-relative z-index-1"
                                        >
                                            <a class="d-block">
                                                <img
                                                    :src="imagePath + item.img"
                                                    class="border-radius-lg product_img"
                                                    :alt="item.name"
                                                    :style="[
                                                        item.stock == 0
                                                            ? { opacity: '0.3' }
                                                            : '',
                                                    ]"
                                                />
                                                <div
                                                    class="soldout-text"
                                                    :style="[
                                                        item.stock == 0
                                                            ? {
                                                                  display:
                                                                      'flex !important',
                                                              }
                                                            : '',
                                                    ]"
                                                >
                                                    SOLD OUT
                                                </div>
                                            </a>
                                        </div>

                                        <div
                                            class="card-body pt-2"
                                            :style="[
                                                item.stock == 0
                                                    ? { opacity: '0.3' }
                                                    : '',
                                            ]"
                                        >
                                            <span
                                                class="text-gradient text-primary cate-text font-weight-bold my-2"
                                                >{{ item.category }}</span
                                            >
                                            <a
                                                class="card-title text-sm d-block text-darker font-weight-bold product_name"
                                            >
                                                {{ item.name }}
                                            </a>
                                            <div
                                                class="text-sm text-danger"
                                                style="font-weight: 900"
                                            >
                                                ฿{{
                                                    item.discount != 0
                                                        ? item.price -
                                                          item.discount
                                                        : item.price
                                                }}
                                                <span
                                                    class="text-xs ms-1"
                                                    style="opacity: 0.8"
                                                    v-if="item.discount != 0"
                                                    ><del
                                                        >฿{{ item.price }}</del
                                                    ></span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <InfiniteLoading
                                @infinite="load"
                                :identifier="reset"
                                class="text-center"
                            />
                        </div>
                        <div
                            class="row w-100 mx-auto g-3"
                            v-if="state === 'search'"
                        >
                            <div
                                class="col-lg-3 col-sm-4 col-6 position-relative"
                                v-for="item in productSearch"
                                :key="item.id"
                            >
                                <router-link
                                    :to="`/product/${item.id}`"
                                    class="products_card card mb-3"
                                    :class="{ disabled: item.stock === 0 }"
                                >
                                    <div
                                        class="overlay-soldout"
                                        :style="[
                                            item.stock == 0
                                                ? { display: 'block' }
                                                : '',
                                        ]"
                                    >
                                        <div class="soldout-text">SOLD OUT</div>
                                    </div>
                                    <div class="product-banner">
                                        <img
                                            src="../assets/new.png"
                                            :alt="item.name + ' New'"
                                            class="banner"
                                            v-if="item.new == 1"
                                        />
                                        <img
                                            src="../assets/sale.png"
                                            :alt="item.name + ' Sale'"
                                            class="banner"
                                            v-if="item.sale == 1"
                                        />
                                        <img
                                            src="../assets/hot.png"
                                            :alt="item.name + ' Hot'"
                                            class="banner"
                                            v-if="item.hot == 1"
                                        />
                                        <img
                                            src="../assets/update.png"
                                            :alt="item.name + ' Update'"
                                            class="banner"
                                            v-if="item.p_update == 1"
                                        />
                                    </div>
                                    <div class="h-100">
                                        <div
                                            class="card-header p-0 mx-3 mt-3 position-relative z-index-1"
                                        >
                                            <a class="d-block">
                                                <img
                                                    :src="imagePath + item.img"
                                                    class="border-radius-lg product_img"
                                                    :alt="item.name"
                                                    :style="[
                                                        item.stock == 0
                                                            ? { opacity: '0.3' }
                                                            : '',
                                                    ]"
                                                />
                                                <div
                                                    class="soldout-text"
                                                    :style="[
                                                        item.stock == 0
                                                            ? {
                                                                  display:
                                                                      'flex !important',
                                                              }
                                                            : '',
                                                    ]"
                                                >
                                                    SOLD OUT
                                                </div>
                                            </a>
                                        </div>

                                        <div
                                            class="card-body pt-2"
                                            :style="[
                                                item.stock == 0
                                                    ? { opacity: '0.3' }
                                                    : '',
                                            ]"
                                        >
                                            <span
                                                class="text-gradient text-primary cate-text font-weight-bold my-2"
                                                >{{ item.category }}</span
                                            >
                                            <a
                                                class="card-title text-sm d-block text-darker font-weight-bold product_name"
                                            >
                                                {{ item.name }}
                                            </a>
                                            <div
                                                class="text-sm text-danger"
                                                style="font-weight: 900"
                                            >
                                                ฿{{
                                                    item.discount != 0
                                                        ? item.price -
                                                          item.discount
                                                        : item.price
                                                }}
                                                <span
                                                    class="text-xs ms-1"
                                                    style="opacity: 0.8"
                                                    v-if="item.discount != 0"
                                                    ><del
                                                        >฿{{ item.price }}</del
                                                    ></span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 card-body" v-else>
                        <div class="w-100 mx-auto px-2">
                            <div class="d-flex justify-content-center mb-2">
                                <div class="text-center badge badge-primary">
                                    เวลาทำการ
                                    <div class="mt-3">
                                        จ-ศ 10.00 น. - 22.00 น.
                                    </div>
                                    <div class="mt-1">
                                        ส-อา 10.00 น. - 21.00 น.
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label for="r-desc"
                                    ><span class="text-danger">* </span
                                    >ประเภทรับ-ส่ง</label
                                >
                                <select
                                    class="form-control"
                                    v-model="messenger.Type"
                                >
                                    <option value="เอกสาร">เอกสาร</option>
                                    <option value="กล่องพัสดุ">
                                        กล่องพัสดุ
                                    </option>
                                    <option value="เวชภัณฑ์">เวชภัณฑ์</option>
                                    <option value="เครื่องดื่ม">
                                        เครื่องดื่ม
                                    </option>
                                    <option value="วินมอเตอร์ไซค์">
                                        วินมอเตอร์ไซค์
                                    </option>
                                    <option value="รับขนย้ายหอพัก/ขนย้ายของ">
                                        รับขนย้ายหอพัก/ขนย้ายของ
                                    </option>
                                    <option value="อื่นๆ">อื่นๆ</option>
                                </select>
                            </div>
                            <div
                                v-if="
                                    messenger.Type === 'กล่องพัสดุ' ||
                                    messenger.Type === 'อื่นๆ'
                                "
                                class="mt-1"
                            >
                                <label>
                                    <span class="badge bg-warning"
                                        >ขนาด กว้าง*ยาว*สูง ไม่เกิน
                                        20ซม*30ซม*20ซม</span
                                    >
                                    <br />
                                    <span class="badge bg-warning"
                                        >น้ำหนักไม่เกิน 5 kg</span
                                    >
                                </label>
                            </div>
                            <div
                                v-if="messenger.Type === 'วินมอเตอร์ไซค์'"
                                class="mt-1"
                            >
                                <label>
                                    <span class="badge bg-warning"
                                        >สามารถซ้อนผู้โดยสารได้ 1
                                        คนเท่านั้น</span
                                    >
                                </label>
                            </div>
                            <div v-if="messenger.Type !== 'วินมอเตอร์ไซค์'">
                                <label for="r-parcel"
                                    ><span class="text-danger">*</span>
                                    รายละเอียด:สิ่งของ</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="รายละเอียด:สิ่งของ"
                                    v-model="messenger.Percel"
                                />
                            </div>
                            <div
                                class="mt-3"
                                v-if="messenger.Type !== 'เครื่องดื่ม'"
                            >
                                <h1 class="text-center fs-6">จุดส่ง</h1>
                                <longdo-map
                                    @load="mapTop"
                                    :lastView="false"
                                    :zoom="17"
                                />
                                <!-- <div class="my-1">
                                    <label for="name"
                                        ><span class="text-danger">*</span>
                                        ชื่อ</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="ชื่อ"
                                        v-model="messenger.sName"
                                    />
                                </div> -->
                                <div
                                    class="my-1"
                                    v-if="messenger.Type !== 'วินมอเตอร์ไซค์'"
                                >
                                    <label for="phone"
                                        ><span class="text-danger">*</span>
                                        เบอร์ติดต่อ</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="เบอร์ติดต่อ"
                                        v-model="messenger.sPhone"
                                        maxlength="10"
                                    />
                                </div>
                                <div class="mb-1">
                                    <label for="localtion"
                                        ><span class="text-danger">*</span>
                                        รายละเอียดที่อยู่</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="รายละเอียดที่อยู่"
                                        v-model="messenger.sLocation"
                                        maxlength="10"
                                    />
                                </div>
                                <div class="mb-1">
                                    <label for="desc">หมายเหตุ</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="หมายเหตุ"
                                        v-model="messenger.sDesc"
                                    />
                                </div>
                            </div>
                            <div
                                :class="
                                    messenger.Type === 'เครื่องดื่ม'
                                        ? 'mt-3'
                                        : 'mt-4'
                                "
                            >
                                <h1 class="text-center fs-6">จุดรับ</h1>
                                <longdo-map
                                    @load="mapBottom"
                                    :lastView="false"
                                    :zoom="17"
                                />
                                <!-- <div class="my-1">
                                    <label for="r-name"
                                        ><span class="text-danger">*</span>
                                        ชื่อ</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="ชื่อ"
                                        v-model="messenger.rName"
                                    />
                                </div> -->
                                <div class="my-1">
                                    <label for="r-phone"
                                        ><span class="text-danger">*</span>
                                        เบอร์ติดต่อ</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="เบอร์ติดต่อ"
                                        v-model="messenger.rPhone"
                                        maxlength="10"
                                    />
                                </div>
                                <div class="mb-1">
                                    <label for="r-localtion"
                                        ><span class="text-danger">*</span>
                                        รายละเอียดที่อยู่</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="รายละเอียดที่อยู่"
                                        v-model="messenger.rLocation"
                                        maxlength="10"
                                    />
                                </div>
                                <div class="mb-1">
                                    <label for="r-desc">หมายเหตุ</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="หมายเหตุ"
                                        v-model="messenger.rDesc"
                                    />
                                </div>
                            </div>
                            <div class="text-center my-3">
                                <argon-button
                                    color="primary"
                                    variant="gradient"
                                    class="w-100"
                                    @click="submitMessenger"
                                    >ยืนยัน</argon-button
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "../services/axios";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import ArgonButton from "@/components/ArgonButton.vue";
import Boardcast from "./Boardcast.vue";
import liff from "@line/liff";
import { mapGetters } from "vuex";

export default {
    name: "ProductsList",
    components: {
        InfiniteLoading,
        ArgonButton,
        Boardcast,
    },
    data() {
        return {
            headName: "สินค้าทั้งหมด",
            products: [],
            productSearch: [],
            category: [],
            selectCategory: "all",
            page: 0,
            npp: 20,
            search: "",
            state: "all",
            imagePath: process.env.VUE_APP_IMAGE_PATH,
            shop: "",
            reset: false,
            map: {
                top: null,
                bottom: null,
            },
            messenger: {
                sPhone: "",
                sLocation: "",
                sDesc: "",
                rPhone: "",
                rLocation: "",
                rDesc: "",
                Type: "เอกสาร",
                Percel: "",
            },
            checkStation: "",
            checkMessenger: false,
            messengerFirst: false,
        };
    },
    created() {
        let shop = localStorage.getItem("station");
        this.checkStation = localStorage.getItem("station");
        this.shop = shop == "deli" ? "loei" : shop;
        this.fetchCategory();
        if (this.Page !== "") {
            if (this.Page === "messenger") {
                this.selectCategory = "messenger";
                this.messengerFirst = true;
            } else {
                this.selectCategory = this.Page;
            }
        }
        this.$store.dispatch("savePage", "");
        this.fetchMessenger();
        if (this.PreviousCategory !== "") {
            this.selectCategory = this.PreviousCategory;
        }
    },
    methods: {
        mapTop(map) {
            const getPositionSuccess = (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;

                map.location({ lon: longitude, lat: latitude }, true);
            };

            const getPositionError = (err) => {
                alert("ไม่สามารถดึงข้อมูล Location ได้");
                console.log(err);
                this.selectCategory = "all";
                this.state = "all";
            };

            navigator.geolocation.getCurrentPosition(
                getPositionSuccess,
                getPositionError
            );

            const getLocation = (locate) => {
                this.map.top = locate;
            };

            map.Event.bind("location", function () {
                map.Overlays.clear();
                let location = map.location();
                let marker = new window.longdo.Marker(location);
                map.Overlays.add(marker);
                getLocation(location);
            });
        },
        mapBottom(map) {
            const getPositionSuccess = (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;

                map.location({ lon: longitude, lat: latitude }, true);
            };

            const getPositionError = (err) => {
                alert("ไม่สามารถดึงข้อมูล Location ได้");
                console.log(err);
                this.selectCategory = "all";
                this.state = "all";
            };

            navigator.geolocation.getCurrentPosition(
                getPositionSuccess,
                getPositionError
            );

            const getLocation = (locate) => {
                this.map.bottom = locate;
            };

            map.Event.bind("location", function () {
                map.Overlays.clear();
                let location = map.location();
                let marker = new window.longdo.Marker(location);
                map.Overlays.add(marker);
                getLocation(location);
            });
        },
        submitMessenger() {
            if (this.messenger.Type === "เครื่องดื่ม") {
                if (
                    this.messenger.rPhone &&
                    this.messenger.rLocation &&
                    this.messenger.Type &&
                    this.messenger.Percel
                ) {
                    this.$swal({
                        text: "ยืนยันการสั่ง ?",
                        showCancelButton: true,
                        confirmButtonText: "ยืนยัน",
                        cancelButtonText: "ยกเลิก",
                        reverseButtons: true,
                        customClass: {
                            confirmButton: "btn bg-gradient-success",
                            cancelButton: "btn bg-gradient-danger",
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            let locate;
                            if (this.shop === "loei") {
                                locate = "อ.เมือง";
                            } else if (this.shop === "wang") {
                                locate = "อ.วังสะพุง";
                            } else {
                                locate = "อ.เชียงคาน";
                            }

                            let rLat = this.map.bottom.lat;
                            let rLon = this.map.bottom.lon;
                            let text =
                                "เมสเซนเจอร์ (" +
                                locate +
                                ")\n\n" +
                                "สถานที่ส่ง\n" +
                                "เบอร์โทรศัพท์: " +
                                this.messenger.rPhone +
                                "\nรายละเอียดที่อยู่: " +
                                this.messenger.rLocation +
                                "\nหมายเหตุ: " +
                                this.messenger.rDesc +
                                "\nlocation: https://www.google.com/maps?q=" +
                                rLat.toFixed(5) +
                                "," +
                                rLon.toFixed(5) +
                                "\n\nโซน:\n\n" +
                                "สินค้ารับหิ้ว: " +
                                this.messenger.Type +
                                "\nรายละเอียด:สิ่งของ: " +
                                this.messenger.Percel +
                                "\n\nยอดสินค้า: 0฿\nยอดค่าส่ง:\n\nรวมทั้งหมด:\nการชำระเงิน: โอนปลายทาง";

                            liff.sendMessages([
                                {
                                    type: "text",
                                    text: text,
                                },
                            ])
                                .then(() => {
                                    liff.closeWindow();
                                })
                                .catch((err) => {
                                    alert(err);
                                    console.log(err);
                                });
                        }
                    });
                } else {
                    this.$swal({
                        text: "กรอกข้อมูลให้ครบถ้วน",
                        icon: "error",
                    });
                }
            } else if (this.messenger.Type === "วินมอเตอร์ไซค์") {
                if (
                    this.messenger.sLocation &&
                    this.messenger.rPhone &&
                    this.messenger.rLocation &&
                    this.messenger.Type
                ) {
                    this.$swal({
                        text: "ยืนยันการสั่ง ?",
                        showCancelButton: true,
                        confirmButtonText: "ยืนยัน",
                        cancelButtonText: "ยกเลิก",
                        reverseButtons: true,
                        customClass: {
                            confirmButton: "btn bg-gradient-success",
                            cancelButton: "btn bg-gradient-danger",
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            let locate;
                            if (this.shop === "loei") {
                                locate = "อ.เมือง";
                            } else if (this.shop === "wang") {
                                locate = "อ.วังสะพุง";
                            } else {
                                locate = "อ.เชียงคาน";
                            }

                            let sLat = this.map.top.lat;
                            let sLon = this.map.top.lon;
                            let rLat = this.map.bottom.lat;
                            let rLon = this.map.bottom.lon;
                            let text =
                                "เมสเซนเจอร์ (" +
                                locate +
                                ")\n\n" +
                                "สถานที่รับ\n" +
                                "รายละเอียดที่อยู่: " +
                                this.messenger.rLocation +
                                "\n" +
                                "หมายเหตุ: " +
                                this.messenger.rDesc +
                                "\nlocation: https://www.google.com/maps?q=" +
                                rLat.toFixed(5) +
                                "," +
                                rLon.toFixed(5) +
                                "\n\n" +
                                "สถานที่ส่ง\n" +
                                "เบอร์โทรศัพท์: " +
                                this.messenger.rPhone +
                                "\nรายละเอียดที่อยู่: " +
                                this.messenger.sLocation +
                                "\nหมายเหตุ: " +
                                this.messenger.sDesc +
                                "\nlocation: https://www.google.com/maps?q=" +
                                sLat.toFixed(5) +
                                "," +
                                sLon.toFixed(5) +
                                "\n\nโซน:\n\n" +
                                "สินค้ารับหิ้ว: " +
                                this.messenger.Type +
                                "\n\nยอดสินค้า: 0฿\nยอดค่าส่ง:\n\nรวมทั้งหมด:\nการชำระเงิน: โอนปลายทาง";

                            liff.sendMessages([
                                {
                                    type: "text",
                                    text: text,
                                },
                            ])
                                .then(() => {
                                    liff.closeWindow();
                                })
                                .catch((err) => {
                                    alert(err);
                                    console.log(err);
                                });
                        }
                    });
                } else {
                    this.$swal({
                        text: "กรอกข้อมูลให้ครบถ้วน",
                        icon: "error",
                    });
                }
            } else {
                if (
                    this.messenger.sPhone &&
                    this.messenger.sLocation &&
                    this.messenger.rPhone &&
                    this.messenger.rLocation &&
                    this.messenger.Type &&
                    this.messenger.Percel
                ) {
                    this.$swal({
                        text: "ยืนยันการสั่ง ?",
                        showCancelButton: true,
                        confirmButtonText: "ยืนยัน",
                        cancelButtonText: "ยกเลิก",
                        reverseButtons: true,
                        customClass: {
                            confirmButton: "btn bg-gradient-success",
                            cancelButton: "btn bg-gradient-danger",
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            let locate;
                            if (this.shop === "loei") {
                                locate = "อ.เมือง";
                            } else if (this.shop === "wang") {
                                locate = "อ.วังสะพุง";
                            } else {
                                locate = "อ.เชียงคาน";
                            }

                            let sLat = this.map.top.lat;
                            let sLon = this.map.top.lon;
                            let rLat = this.map.bottom.lat;
                            let rLon = this.map.bottom.lon;
                            let text =
                                "เมสเซนเจอร์ (" +
                                locate +
                                ")\n\n" +
                                "สถานที่รับ\n" +
                                "เบอร์โทรศัพท์: " +
                                this.messenger.rPhone +
                                "\nรายละเอียดที่อยู่: " +
                                this.messenger.rLocation +
                                "\n" +
                                "หมายเหตุ: " +
                                this.messenger.rDesc +
                                "\nlocation: https://www.google.com/maps?q=" +
                                rLat.toFixed(5) +
                                "," +
                                rLon.toFixed(5) +
                                "\n\n" +
                                "สถานที่ส่ง\n" +
                                "เบอร์โทรศัพท์: " +
                                this.messenger.sPhone +
                                "\nรายละเอียดที่อยู่: " +
                                this.messenger.sLocation +
                                "\nหมายเหตุ: " +
                                this.messenger.sDesc +
                                "\nlocation: https://www.google.com/maps?q=" +
                                sLat.toFixed(5) +
                                "," +
                                sLon.toFixed(5) +
                                "\n\nโซน:\n\n" +
                                "สินค้ารับหิ้ว: " +
                                this.messenger.Type +
                                "\nรายละเอียด:สิ่งของ: " +
                                this.messenger.Percel +
                                "\n\nยอดสินค้า: 0฿\nยอดค่าส่ง:\n\nรวมทั้งหมด:\nการชำระเงิน: โอนปลายทาง";

                            liff.sendMessages([
                                {
                                    type: "text",
                                    text: text,
                                },
                            ])
                                .then(() => {
                                    liff.closeWindow();
                                })
                                .catch((err) => {
                                    alert(err);
                                    console.log(err);
                                });
                        }
                    });
                } else {
                    this.$swal({
                        text: "กรอกข้อมูลให้ครบถ้วน",
                        icon: "error",
                    });
                }
            }
        },
        resetLoadingData() {
            this.state = "all";
            this.page = 0;
            this.reset = !this.reset;
            this.products = [];
            this.$store.dispatch("savePreviousCategory", this.selectCategory);
        },
        submitSearch() {
            if (this.search) {
                axios
                    .get(`/search?search=${this.search}&shop=${this.shop}`)
                    .then((resp) => {
                        if (resp.data.status === "success") {
                            this.selectCategory = "";
                            this.search = "";
                            this.state = "search";
                            this.productSearch = resp.data.data;
                            this.$store.dispatch("savePreviousCategory", "");
                        }
                    })
                    .catch((err) => {
                        if (err?.response) {
                            if (err?.response?.status !== 500) {
                                this.$swal({
                                    text: err?.response?.data?.message
                                        ? err?.response?.data?.message
                                        : "ไม่สามารถโหลดข้อมูลสินค้าได้ กรุณาลองใหม่",
                                    icon: "error",
                                });
                            }
                        }
                    });
            } else {
                this.selectCategory = "all";
                this.state = "all";
            }
        },
        load($state) {
            axios
                .get(
                    `/products?category=${this.selectCategory}&page=${this.page}&npp=${this.npp}&shop=${this.shop}`
                )
                .then((resp) => {
                    if (resp.data.status === "success") {
                        if (resp.data.data.result.length < 1) $state.complete();
                        else {
                            this.products.push(...resp.data.data.result);
                            $state.loaded();
                            this.page = this.page + 1;
                        }
                    }
                })
                .catch((err) => {
                    if (err?.response) {
                        if (err?.response?.status !== 500) {
                            this.$swal({
                                text: err?.response?.data?.message
                                    ? err?.response?.data?.message
                                    : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่",
                                icon: "error",
                            });
                        }
                    }
                });
        },
        fetchCategory() {
            axios
                .get("/category")
                .then((resp) => {
                    if (resp.data.status === "success") {
                        this.category = resp.data.data;
                    }
                })
                .catch((err) => {
                    if (err?.response) {
                        if (err?.response?.status !== 500) {
                            this.$swal({
                                text: err?.response?.data?.message
                                    ? err?.response?.data?.message
                                    : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่",
                                icon: "error",
                            });
                        }
                    }
                });
        },
        fetchMessenger() {
            axios
                .get("/messenger")
                .then((resp) => {
                    if (resp.data.status === "success") {
                        let found = resp.data.data.find((el) => {
                            return el.station === this.checkStation;
                        });

                        this.checkMessenger = found.status;
                    }
                })
                .catch((err) => {
                    if (err?.response) {
                        if (err?.response?.status !== 500) {
                            this.$swal({
                                text: err?.response?.data?.message
                                    ? err?.response?.data?.message
                                    : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่",
                                icon: "error",
                            });
                        }
                    }
                });
        },
    },
    computed: {
        ...mapGetters(["Page"]),
        ...mapGetters(["PreviousCategory"]),
    },
};
</script>

<style scoped>
.soldout-text {
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: #161616;
    font-weight: bold;
    width: 105px;
    height: 35px;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    font-size: 16px;
    color: white;
    transform: translate(-50%, -50%);
    z-index: 15;
    display: none !important;
}

a.disabled {
    pointer-events: none;
    cursor: default;
}

a:hover {
    color: inherit;
}
.product_name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.products_card:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 1s;
    cursor: pointer;
}

.products_card .product_img {
    width: 100%;
    height: 120px;
    object-fit: cover;
}

.cate-text {
    font-size: 0.55em;
}

.product-banner {
    position: absolute;
    top: 2%;
    left: 6%;
    z-index: 2;
}

.banner {
    margin-right: 2px;
    width: auto;
    height: 30px;
}

@media only screen and (min-width: 420px) {
    .products_card .product_img {
        height: 170px;
    }

    .cate-text {
        font-size: 0.7em;
    }

    .banner {
        height: 30px;
    }
}

@media only screen and (min-width: 576px) {
    .products_card .product_img {
        height: 135px;
    }
    .cate-text {
        font-size: 0.65em;
    }
    .banner {
        height: 30px;
    }
}

@media only screen and (min-width: 768px) {
    .products_card .product_img {
        height: 145px;
    }
    .banner {
        height: 30px;
    }
}

@media only screen and (min-width: 992px) {
    .products_card .product_img {
        height: 155px;
    }

    .cate-text {
        font-size: 0.7em;
    }
    .banner {
        height: 30px;
    }
}

@media only screen and (min-width: 1200px) {
    .products_card .product_img {
        height: 165px;
    }

    .cate-text {
        font-size: 0.75em;
    }
    .banner {
        height: 30px;
    }
}

@media only screen and (min-width: 1400px) {
    .products_card .product_img {
        height: 210px;
    }

    .cate-text {
        font-size: 0.8em;
    }
    .banner {
        height: 35px;
    }
}

@media only screen and (min-width: 1700px) {
    .products_card .product_img {
        height: 250px;
    }
    .banner {
        height: 35px;
    }
}

@media only screen and (min-width: 1900px) {
    .products_card .product_img {
        height: 270px;
    }
    .banner {
        height: 35px;
    }
}

.btn-category {
    border: none;
    background-color: transparent;
    width: 100%;
    height: 35px;
}

.cate-card {
    height: auto;
}
</style>
