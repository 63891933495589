<template>
    <div class="full-loading" v-if="loading">
        <clip-loader
            :loading="loading"
            color="#99ABAC"
            size="50px"
        ></clip-loader>
    </div>
    <div class="station-container" v-if="dropdown">
        <div class="container d-flex justify-content-center">
            <div
                class="text-center badge badge-warning mb-5"
                style="font-size: 1rem; white-space: normal !important"
                v-html="announce"
            ></div>
        </div>
        <img
            src="../assets/logo_new.webp"
            class="logo"
            alt="logo"
            :style="[
                announceImg
                    ? { marginBottom: '10px' }
                    : { marginBottom: '40px' },
            ]"
        />
        <img
            :src="announceImg"
            class="announce"
            alt="anounce_image"
            style="margin-bottom: 15px"
            v-if="announceImg"
        />
        <div class="dropdown">
            <button
                class="btn bg-gradient-primary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                เลือกการจัดส่ง
            </button>
            <ul
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                v-if="directMessenger"
            >
                <li v-if="messengerStatus.loei">
                    <a class="dropdown-item" @click="setStation('loei')"
                        >ไรเดอร์ อ.เมืองเลย</a
                    >
                </li>
                <li v-if="messengerStatus.wang">
                    <a class="dropdown-item" @click="setStation('wang')"
                        >ไรเดอร์ อ.วังสะพุง</a
                    >
                </li>
                <li v-if="messengerStatus.ck">
                    <a class="dropdown-item" @click="setStation('ck')"
                        >ไรเดอร์ อ.เชียงคาน</a
                    >
                </li>
            </ul>
            <ul
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                v-else
            >
                <li>
                    <a class="dropdown-item" @click="setStation('loei')"
                        >ไรเดอร์ อ.เมืองเลย</a
                    >
                </li>
                <li>
                    <a class="dropdown-item" @click="setStation('wang')"
                        >ไรเดอร์ อ.วังสะพุง</a
                    >
                </li>
                <li>
                    <a class="dropdown-item" @click="setStation('ck')"
                        >ไรเดอร์ อ.เชียงคาน</a
                    >
                </li>
                <li>
                    <a class="dropdown-item" @click="setStation('deli')"
                        >จัดส่งแบบพัสดุ</a
                    >
                </li>
            </ul>
        </div>
        <div
            class="text-center badge badge-secondary"
            style="font-size: 0.85rem"
            :style="[
                announceImg ? { marginTop: '5px' } : { marginTop: '40px' },
            ]"
            v-html="timesetting"
        ></div>
    </div>
    <div v-if="!dropdown">
        <div
            class="min-height-300 position-absolute w-100"
            style="background-color: #d6e4e5"
        />
        <main class="main-content position-relative max-height-vh-100 h-100">
            <navbar />
            <router-view v-if="hasLoadedData" />
            <app-footer />
            <div id="account-actions">
                <div class="x-button-actions" id="account-actions-mobile">
                    <div class="-outer-wrapper">
                        <div class="-left-wrapper">
                            <router-link
                                to="/"
                                class="-item-wrapper -line"
                                rel="noopener noreferrer"
                            >
                                <i class="fas fa-home text-lg"></i>
                                <span class="-text">หน้าแรก</span>
                            </router-link>
                            <router-link
                                to="/cart"
                                class="-item-wrapper -promotion"
                            >
                                <i class="fas fa-shopping-cart text-lg"></i>
                                <span class="-text">ตะกร้า</span>
                            </router-link>
                        </div>
                        <div
                            class="-fully-overlay js-footer-lobby-overlay"
                        ></div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import AppFooter from "../components/Footer.vue";
import liff from "@line/liff";
import { mapGetters } from "vuex";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import axios from "../services/axios";

export default {
    name: "App",
    components: {
        Navbar,
        AppFooter,
        ClipLoader,
    },
    data() {
        return {
            dropdown: true,
            loading: true,
            directMessenger: false,
            announce: "",
            announceImg: null,
            timesetting: "",
            messengerStatus: {
                loei: false,
                wang: false,
                ck: false,
            },
        };
    },
    created() {
        this.fetchMessenger();
        if (this.$route.query.page) {
            this.$store.dispatch("savePage", this.$route.query.page);
        }
        if (this.$route.query.page === "messenger") {
            this.directMessenger = true;
        }
        // liff.init({ liffId: "1657878767-o2Ye000d" }).then(() => {
        liff.init({ liffId: "1657901154-lAgo47b1" }).then(() => {
            if (liff.isInClient()) {
                if (!liff.isLoggedIn()) {
                    liff.login();
                } else {
                    liff.getFriendship().then((res) => {
                        if (!res.friendFlag) {
                            window.location = process.env.VUE_APP_LINE_OA;
                        } else {
                            liff.getProfile()
                                .then((profile) => {
                                    this.$store.dispatch(
                                        "saveProfile",
                                        profile
                                    );
                                    this.loading = false;
                                    this.fetchAnnounce();
                                    this.fetchTimeSetting();
                                })
                                .catch((err) => {
                                    alert(err);
                                });
                        }
                    });
                }
            } else {
                window.location.href = "/404";
            }
        });
    },
    methods: {
        fetchAnnounce() {
            axios
                .get("/announce")
                .then((resp) => {
                    const text = resp.data.data[0].text;
                    this.announce = text.replace(/(\\r)*\\n/g, "<br>");
                    this.announceImg = resp.data.data[0].img
                        ? `https://api.bbmshop.one/images/${resp.data.data[0].img}`
                        : null;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        fetchTimeSetting() {
            axios
                .get("/timesetting")
                .then((resp) => {
                    const text = resp.data.data[0].text;
                    this.timesetting = text.replace(/(\\r)*\\n/g, "<br>");
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        fetchMessenger() {
            axios
                .get("/messenger")
                .then((resp) => {
                    if (resp.data.status === "success") {
                        resp.data.data.forEach((el) => {
                            for (const key in this.messengerStatus) {
                                if (key === el.station) {
                                    this.messengerStatus[key] = el.status;
                                }
                            }
                        });
                    }
                })
                .catch((err) => {
                    if (err?.response) {
                        if (err?.response?.status !== 500) {
                            this.$swal({
                                text: err?.response?.data?.message
                                    ? err?.response?.data?.message
                                    : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่",
                                icon: "error",
                            });
                        }
                    }
                });
        },
        setStation(station) {
            localStorage.setItem("station", station);
            this.dropdown = false;
        },
    },
    computed: {
        ...mapGetters(["Profile"]),
        hasLoadedData() {
            return this.$store.state.profile.userId !== undefined;
        },
    },
};
</script>

<style scoped>
.station-container {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 100%;
    height: 100%;

    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    overflow-y: scroll;

    background-color: #d6e4e5;
}

.station-container .logo {
    width: 150px;
}

.station-container .announce {
    max-height: 170px;
    max-width: 80%;
    width: auto;
    border-radius: 10px;
}

#account-actions-mobile {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 4;
    width: 100%;
}

@media (min-width: 992px) {
    #account-actions-mobile {
        display: none;
    }
}

#account-actions-mobile .-outer-wrapper {
    display: flex;
    align-items: flex-end;
}

#account-actions-mobile .-left-wrapper,
#account-actions-mobile .-right-wrapper {
    display: flex;
    align-items: flex-end;
    flex: 1;
    z-index: 2;
    height: 70px;
    padding-bottom: 6px;
    background: linear-gradient(180deg, #d6e4e5, #d6e4e5);
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

#account-actions-mobile .-center-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 2;
    width: 74px;
    height: 74px;
    margin: 0 8px 16px;
    padding-bottom: 0px;
    color: #fff;
    border-radius: 60%;
    box-shadow: 0 0 10px hsla(0, 0%, 100%, 0.4);
    background: linear-gradient(180deg, #fbc844, #f88012);
}

#account-actions-mobile .-center-wrapper .-selected,
#account-actions-mobile .-center-wrapper .-selecting {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
}

#account-actions-mobile .-center-wrapper .-selected {
    transition: all 0.4s;
    height: 100%;
    width: 100%;
    border-radius: 15%;
}

#account-actions-mobile .-center-wrapper .-selected:before {
    content: "";
    position: relative;
    bottom: 0rem;
    width: 100%;
    height: 100%;
    z-index: 4;
    background-size: 77px;
    background-repeat: no-repeat;
    background-position: bottom;
}

#account-actions-mobile .-center-wrapper .-selected .-text {
    position: relative;
    top: -3px;
    font-size: 0.875rem;
    text-shadow: 1px 3px 5px rgba(0, 0, 0, 0.6);
}

#account-actions-mobile .-center-wrapper .-selected .-bottom-curve,
#account-actions-mobile .-center-wrapper .-selected .-top-curve {
    position: absolute;
    left: 50%;
    z-index: 2;
    width: 85%;
    height: auto;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

#account-actions-mobile .-center-wrapper .-selected .-top-curve {
    top: 3px;
}

#account-actions-mobile .-center-wrapper .-selected .-bottom-curve {
    bottom: 3px;
}

#account-actions-mobile .-center-wrapper:hover {
    color: #fff;
    text-decoration: none;
}

#account-actions-mobile .-fake-center-bg-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 50px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
    overflow: hidden;
}

#account-actions-mobile .-fake-center-bg-wrapper svg {
    position: absolute;
    left: 50%;
    bottom: 0;
    height: 108px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

#account-actions-mobile .-fake-center-bg-wrapper svg path {
    fill: url(#rectangleGradient);
}

#account-actions-mobile .-left-wrapper {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

#account-actions-mobile .-right-wrapper {
    border-top-right-radius: 10px;
}

#account-actions-mobile .-sub-menu-lobby-wrapper {
    position: absolute;
    flex-direction: row;
    justify-content: center;
    visibility: hidden;
    top: -75px;
    left: 50%;
    z-index: 3;
    opacity: 0;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    -webkit-transform: translateX(-50%) translateY(200px) scaleY(2.5)
        scaleX(0.2);
    transform: translateX(-50%) translateY(200px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    transition: all 0.2s;
}

#account-actions-mobile .-sub-menu-lobby-wrapper .nav-item .-img {
    margin-top: -8px;
    width: 56px;
    height: auto;
}

#account-actions-mobile .-sub-menu-lobby-wrapper .nav-item .-text {
    display: inline-block;
    font-size: 10px;
    line-height: 12px;
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
}

@media (max-width: 991.98px) {
    #account-actions-mobile
        .-sub-menu-lobby-wrapper
        .nav-item.-casino
        .-nav-icon-bg {
        background-position: 3px;
    }
}

@media (max-width: 360px) {
    #account-actions-mobile
        .-sub-menu-lobby-wrapper
        .nav-item.-casino
        .-nav-icon-bg {
        background-position: 0 0;
    }
}

@media (max-width: 991.98px) {
    #account-actions-mobile
        .-sub-menu-lobby-wrapper
        .nav-item.-slot
        .-nav-icon-bg {
        background-position: -73px;
    }
}

@media (max-width: 360px) {
    #account-actions-mobile
        .-sub-menu-lobby-wrapper
        .nav-item.-slot
        .-nav-icon-bg {
        background-position: -75px;
    }
}

@media (max-width: 991.98px) {
    #account-actions-mobile
        .-sub-menu-lobby-wrapper
        .nav-item.-skill-game
        .-nav-icon-bg {
        background-position: -147px;
    }
}

@media (max-width: 360px) {
    #account-actions-mobile
        .-sub-menu-lobby-wrapper
        .nav-item.-skill-game
        .-nav-icon-bg {
        top: -12px;
        background-position: -150px;
    }
}

@media (max-width: 360px) {
    #account-actions-mobile
        .-sub-menu-lobby-wrapper
        .nav-item.-skill-game
        .-text {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    }
}

@media (max-width: 991.98px) {
    #account-actions-mobile
        .-sub-menu-lobby-wrapper
        .nav-item.-sport
        .-nav-icon-bg {
        background-position: -221px;
    }
}

@media (max-width: 360px) {
    #account-actions-mobile
        .-sub-menu-lobby-wrapper
        .nav-item.-sport
        .-nav-icon-bg {
        background-position: -224px;
    }
}

@media (max-width: 991.98px) {
    #account-actions-mobile
        .-sub-menu-lobby-wrapper
        .nav-item.-fishing-game
        .-nav-icon-bg {
        background-position: -300px;
    }
}

@media (max-width: 360px) {
    #account-actions-mobile
        .-sub-menu-lobby-wrapper
        .nav-item.-fishing-game
        .-nav-icon-bg {
        background-position: -303px;
    }
}

#account-actions-mobile .-sub-menu-lobby-wrapper .nav-link {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 66px;
    height: 66px;
    margin: 0 3px;
    padding: 0;
    text-align: center;
    color: #fff;
    background: linear-gradient(180deg, #393854, #131228);
    border: 1px solid hsla(0, 0%, 100%, 0.6);
    border-radius: 50%;
    box-shadow: 0 0 10px hsla(0, 0%, 100%, 0.6);
}

#account-actions-mobile .-sub-menu-lobby-wrapper .nav-link .-nav-icon-bg {
    background-size: 360px;
    background-repeat: no-repeat;
    width: 100%;
    height: 40px;
    top: -15px;
    position: relative;
}

#account-actions-mobile .-sub-menu-lobby-wrapper .nav-link:focus {
    outline: 0;
    text-decoration: none;
}

@media screen and (max-width: 360px) {
    #account-actions-mobile .-sub-menu-lobby-wrapper .nav-item .-img {
        margin-top: -15px;
        width: 54px;
    }

    #account-actions-mobile .-sub-menu-lobby-wrapper .nav-link {
        width: 60px;
        height: 60px;
        margin: 0 2px;
    }
}

#account-actions-mobile .-item-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: #333;
}

#account-actions-mobile .-item-wrapper .-ic-img {
    width: 32px;
    height: auto;
}

#account-actions-mobile .-item-wrapper .-text {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    color: #333;
    transition: color 0.2s;
}

@media (max-width: 575.98px) {
    #account-actions-mobile .-item-wrapper .-text {
        font-size: 0.8rem;
    }
}

#account-actions-mobile .-item-wrapper:active,
#account-actions-mobile .-item-wrapper:hover {
    text-decoration: none;
}

#account-actions-mobile .-item-wrapper.-register {
    position: relative;
}

#account-actions-mobile .-item-wrapper.-register .-ic-img {
    -webkit-filter: drop-shadow(0 0 3px #f7c16b);
    filter: drop-shadow(0 0 3px #f7c16b);
    -webkit-animation: x-register-bottom-blink 0.5s infinite alternate;
    animation: x-register-bottom-blink 0.5s infinite alternate;
}

#account-actions-mobile .-fully-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(0, 0, 0, 0.6);
}

#account-actions-mobile.-active .-fake-center-bg-wrapper,
#account-actions-mobile.-active .-left-wrapper,
#account-actions-mobile.-active .-right-wrapper {
    -webkit-filter: brightness(0.5);
    filter: brightness(0.5);
}

#account-actions-mobile.-active .-fake-center-bg-wrapper a,
#account-actions-mobile.-active .-left-wrapper a,
#account-actions-mobile.-active .-right-wrapper a {
    pointer-events: none;
}

#account-actions-mobile.-active .-center-wrapper {
    background: linear-gradient(
        0deg,
        rgba(251, 200, 68, 0.85),
        rgba(248, 128, 18, 0.85)
    );
}

#account-actions-mobile.-active .-center-wrapper .-selected {
    display: none;
}

#account-actions-mobile.-active .-center-wrapper .-selecting {
    display: flex;
}

#account-actions-mobile.-active .-sub-menu-lobby-wrapper {
    visibility: visible;
    opacity: 1;
    -webkit-filter: blur(0);
    filter: blur(0);
    -webkit-transform: translateX(-50%) translateY(0) scaleY(1) scaleX(1);
    transform: translateX(-50%) translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

#account-actions-mobile.-active .-fully-overlay {
    display: block;
}
</style>
