import { createRouter, createWebHistory } from "vue-router";

import MainPageLayout from "../views/MainPageLayout.vue";
import ProductPage from "../views/ProductPage.vue";
import ProductsList from "../views/ProductsList.vue";
import OrderDetails from "../views/OrderDetails";
import Error404 from "../views/Error404.vue";
import Order from "../views/Order.vue";

const routes = [
    {
        path: "/",
        redirect: "/",
        component: MainPageLayout,
        name: "MainPageLayout",
        children: [
            {
                path: "/",
                name: "Products List",
                component: ProductsList,
            },
            {
                path: "/product/:id",
                name: "Product Page",
                component: ProductPage,
            },
            {
                path: "/cart",
                name: "Order Details",
                component: OrderDetails,
            },
        ],
    },
    {
        path: "/404",
        name: "Error Error404",
        component: Error404,
    },
    {
        path: "/order/:id",
        name: "Order Page",
        component: Order,
    },
    {
        path: "/:catchAll(.*)",
        name: "Error Error404",
        component: Error404,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
    scrollBehavior() {
        return { top: 0 };
    },
});

export default router;
