<template>
    <div
        class="w-100 h-100 position-fixed d-flex justify-content-center align-items-center top-0 flex-column"
        style="z-index: 50; background-color: rgba(0, 0, 0, 0.9)"
        v-if="zoom != ''"
    >
        <div class="d-flex d-flex justify-content-end" style="width: 93%">
            <i
                class="fas fa-window-close text-danger"
                style="font-size: 1.5rem"
                @click="zoom = ''"
            ></i>
        </div>
        <img :src="imagePath + zoom" style="width: 93%" alt="zoom Image" />
    </div>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-md-8 mx-auto">
                <div class="card">
                    <div class="card-body">
                        <h5 class="mb-4">รายละเอียดสินค้า</h5>
                        <div class="row">
                            <div class="text-center col-xl-5 col-lg-6">
                                <!-- <img
                                    class="mx-auto shadow-lg w-100 border-radius-lg"
                                    :src="imagePath + product.img"
                                    :alt="product.name"
                                /> -->
                                <div
                                    id="carouselExampleIndicators"
                                    class="carousel slide shadow-lg"
                                    data-bs-ride="carousel"
                                >
                                    <div class="carousel-indicators">
                                        <button
                                            type="button"
                                            data-bs-target="#carouselExampleIndicators"
                                            data-bs-slide-to="0"
                                            class="active"
                                            aria-current="true"
                                        ></button>
                                        <button
                                            type="button"
                                            data-bs-target="#carouselExampleIndicators"
                                            :data-bs-slide-to="index + 1"
                                            v-for="(item, index) in images"
                                            :key="index"
                                        ></button>
                                    </div>
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <img
                                                :src="imagePath + product.img"
                                                class="d-block w-100 mx-auto border-radius-lg"
                                                alt="Product Image"
                                                @click="zoom = product.img"
                                            />
                                        </div>
                                        <div
                                            class="carousel-item"
                                            v-for="(item, index) in images"
                                            :key="index"
                                        >
                                            <img
                                                :src="imagePath + item.img"
                                                class="d-block w-100 mx-auto border-radius-lg"
                                                :alt="`Product Image ${
                                                    index + 1
                                                }`"
                                                @click="zoom = item.img"
                                            />
                                        </div>
                                    </div>
                                    <button
                                        class="carousel-control-prev"
                                        type="button"
                                        data-bs-target="#carouselExampleIndicators"
                                        data-bs-slide="prev"
                                    >
                                        <span
                                            class="carousel-control-prev-icon"
                                            aria-hidden="true"
                                        ></span>
                                        <span class="visually-hidden"
                                            >Previous</span
                                        >
                                    </button>
                                    <button
                                        class="carousel-control-next"
                                        type="button"
                                        data-bs-target="#carouselExampleIndicators"
                                        data-bs-slide="next"
                                    >
                                        <span
                                            class="carousel-control-next-icon"
                                            aria-hidden="true"
                                        ></span>
                                        <span class="visually-hidden"
                                            >Next</span
                                        >
                                    </button>
                                </div>
                                <!-- <div
                                    class="my-gallery d-flex mt-4 pt-2"
                                    itemscope
                                    itemtype="http://schema.org/ImageGallery"
                                > -->
                                <!-- <figure
                                        v-for="(item, index) in images"
                                        :key="index"
                                        class="ms-2 me-3"
                                        itemprop="associatedMedia"
                                        itemscope
                                        itemtype="http://schema.org/ImageObject"
                                    >
                                        <a
                                            :href="imagePath + item.img"
                                            itemprop="contentUrl"
                                            data-size="500x600"
                                        >
                                            <img
                                                class="w-100 min-height-100 max-height-100 border-radius-lg shadow"
                                                :src="imagePath + item.img"
                                                alt="Image description"
                                            />
                                        </a>
                                    </figure> -->
                                <!-- </div> -->
                            </div>
                            <div class="mx-auto col-lg-5">
                                <h3 class="mt-4 mt-lg-0">{{ product.name }}</h3>
                                <h6 class="mt-3 mb-0">ราคา</h6>
                                <div class="d-flex">
                                    <div class="text-2xl fw-bold text-dark">
                                        ฿{{ calPrice }}
                                    </div>
                                    <div
                                        class="ms-2 text-sm my-auto"
                                        v-if="product.discount != 0"
                                    >
                                        <del>฿{{ product.price }}</del>
                                    </div>
                                </div>
                                <span
                                    class="badge badge-success"
                                    v-if="currStock > 0"
                                    >มีสินค้า</span
                                >
                                <span class="badge badge-danger" v-else
                                    >สินค้าหมด</span
                                >
                                <br />
                                <label class="mt-4" v-if="product.description"
                                    >รายละเอียด</label
                                >
                                <div
                                    v-if="product.description"
                                    v-html="getDesc"
                                ></div>
                                <div class="mt-4 row">
                                    <div class="mt-2 col-12" v-if="options">
                                        <label>ตัวเลือก</label>
                                        <div class="row">
                                            <div class="col-12">
                                                <button
                                                    v-for="(
                                                        item, index
                                                    ) in product.options"
                                                    :key="index"
                                                    class="btn-options"
                                                    :class="
                                                        options === item.options
                                                            ? 'active'
                                                            : undefined
                                                    "
                                                    :disabled="
                                                        item.instock < 1
                                                    "
                                                    @click="
                                                        options = item.options;
                                                        getStock();
                                                    "
                                                >
                                                    {{ item.options }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <label>จำนวน</label>
                                        <div class="d-flex align-items-center">
                                            <button
                                                type="button"
                                                class="text-lg font-weight-bold"
                                                @click="handleQuantityMinus"
                                                style="
                                                    border: none;
                                                    background-color: transparent;
                                                "
                                            >
                                                -
                                            </button>
                                            <span
                                                class="text-xl mx-2 font-weight-bold"
                                            >
                                                <!-- {{ quantity }} -->
                                                <input
                                                    type="number"
                                                    v-model="quantity"
                                                    @change="
                                                        handleInputQuantity
                                                    "
                                                    style="
                                                        width: auto;
                                                        max-width: 40px;
                                                    "
                                                />
                                            </span>
                                            <button
                                                type="button"
                                                class="text-lg font-weight-bold"
                                                @click="handleQuantityPlus"
                                                style="
                                                    border: none;
                                                    background-color: transparent;
                                                "
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="row mt-4"
                                    v-for="(item, index) in product.pair"
                                    :key="index"
                                >
                                    <div class="col-12">
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                id="flexCheckChecked"
                                                v-model="pairSelect"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="flexCheckChecked"
                                            >
                                                {{ product.pair_showname }}
                                                {{ item.name }}
                                                <span
                                                    class="text-danger fw-bold"
                                                    v-if="
                                                        item.options[0]
                                                            .options === 'ไม่มี'
                                                    "
                                                >
                                                    + {{ item.pair_price }}
                                                </span>
                                            </label>
                                            <div
                                                class="d-flex align-items-center"
                                                v-if="
                                                    item.options[0].options !==
                                                    'ไม่มี'
                                                "
                                            >
                                                <select
                                                    class="form-control w-50"
                                                    v-model="pairOption"
                                                >
                                                    <option value="" disabled>
                                                        เลือก...
                                                    </option>
                                                    <option
                                                        :value="stock.options"
                                                        v-for="(
                                                            stock, index
                                                        ) in item.options"
                                                        :key="index"
                                                        :disabled="
                                                            stock[
                                                                shop == 'deli'
                                                                    ? 'loei'
                                                                    : shop
                                                            ] < 1
                                                        "
                                                    >
                                                        {{ stock.options }}
                                                        <span
                                                            v-if="
                                                                stock[
                                                                    shop ==
                                                                    'deli'
                                                                        ? 'loei'
                                                                        : shop
                                                                ] < 1
                                                            "
                                                        >
                                                            (สินค้าหมด)
                                                        </span>
                                                    </option>
                                                </select>
                                                <div
                                                    class="text-dark fw-bold ms-1"
                                                >
                                                    + ฿{{ item.pair_price }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="row mt-4"
                                    v-if="
                                        product.pair_name &&
                                        product.pair_name === 'เพิ่มนิค'
                                    "
                                >
                                    <div
                                        class="col-12 d-flex align-items-center"
                                    >
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                id="flexCheckChecked"
                                                v-model="pairSelect"
                                            />
                                        </div>
                                        <label
                                            class="form-check-label mt-2"
                                            for="flexCheckChecked"
                                            style="width: 65px"
                                        >
                                            {{ product.pair_showname }}
                                            {{ product.pair_name }}
                                        </label>
                                        <div
                                            class="d-flex align-items-center ms-2"
                                        >
                                            <input
                                                type="number"
                                                class="form-control w-25"
                                                v-model="pairQuantity"
                                            />
                                            <div class="text-dark fw-bold ms-1">
                                                + ฿{{
                                                    product.pair_price *
                                                    pairQuantity
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="row mt-4"
                                    v-if="
                                        product.pair_name &&
                                        product.pair_name != 'เพิ่มนิค'
                                    "
                                >
                                    <div
                                        class="col-12 d-flex align-items-center"
                                    >
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                id="flexCheckChecked"
                                                v-model="pairSelect"
                                            />
                                        </div>
                                        <label
                                            class="form-check-label mt-2"
                                            for="flexCheckChecked"
                                        >
                                            {{ product.pair_showname }}
                                            {{ product.pair_name }}
                                            <span
                                                class="text-dark fw-bold text-lg"
                                                >+ ฿{{
                                                    product.pair_price
                                                }}</span
                                            >
                                        </label>
                                    </div>
                                </div>
                                <div class="mt-4 row">
                                    <div class="col-lg-5">
                                        <button
                                            class="mb-0 btn bg-gradient-success mt-lg-auto w-100"
                                            type="button"
                                            name="button"
                                            @click="addCart"
                                        >
                                            เพิ่มลงตะกร้า
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Choices from "choices.js";
import axios from "../services/axios";
import { mapGetters } from "vuex";

export default {
    name: "ProductPage",
    data() {
        return {
            product: [],
            images: [],
            zoom: "",
            options: "",
            currStock: null,
            quantity: 1,
            pairSelect: false,
            pairQuantity: 1,
            pairOption: "",
            imagePath: process.env.VUE_APP_IMAGE_PATH,
            shop: "",
        };
    },
    created() {
        let shop = localStorage.getItem("station");
        this.shop = shop == "deli" ? "loei" : shop;

        this.fetchData(this.$route.params.id);
    },
    methods: {
        handleInputQuantity() {
            if (this.quantity < 1) {
                this.quantity = 1;
            }
        },
        addCart() {
            if (this.currStock > 0) {
                if (this.quantity > 0) {
                    if (
                        this.product.pair &&
                        this.pairSelect &&
                        this.product.pair[0].options[0].options !== "ไม่มี" &&
                        !this.pairOption
                    ) {
                        this.$swal({
                            text: "เลือกตัวเลือกของรายการซื้อคู่",
                            icon: "error",
                        });
                        return;
                    }
                    if (
                        this.product.pair_name === "เพิ่มนิค" &&
                        this.pairSelect &&
                        this.pairQuantity < 1
                    ) {
                        this.$swal({
                            text: "กรอกจำนวนของรายการเพิ่มเติม",
                            icon: "error",
                        });
                        return;
                    }
                    let pair;
                    let pairPrice;
                    let options = this.options ? this.options : "ไม่มี";
                    if (this.pairSelect && this.product.pair) {
                        pair =
                            this.product.pair[0].name + " " + this.pairOption;
                        pairPrice = this.product.pair[0].pair_price;
                    } else if (
                        this.pairSelect &&
                        this.product.pair_name === "เพิ่มนิค"
                    ) {
                        pair = this.product.pair_name + " " + this.pairQuantity;
                        pairPrice = this.product.pair_price;
                    } else if (
                        this.pairSelect &&
                        this.product.pair_name != "เพิ่มนิค"
                    ) {
                        pair = this.product.pair_name;
                        pairPrice = this.product.pair_price;
                    } else {
                        pair = "ไม่มี";
                        pairPrice = 0;
                    }

                    let cart = JSON.parse(localStorage.getItem("cart"));
                    if (cart) {
                        const found = cart.findIndex(
                            (el) =>
                                el.product === this.product.id &&
                                el.options === options &&
                                el.pair === pair
                        );

                        if (found === -1) {
                            cart = [
                                ...cart,
                                {
                                    product: this.product.id,
                                    options: options,
                                    qty: this.quantity,
                                    pair: pair,
                                    pairPrice: pairPrice,
                                    pairQuantity: this.pairQuantity,
                                },
                            ];
                            localStorage.setItem("cart", JSON.stringify(cart));
                        } else {
                            cart[found].qty = this.quantity;
                            localStorage.setItem("cart", JSON.stringify(cart));
                        }
                    } else {
                        localStorage.setItem(
                            "cart",
                            JSON.stringify([
                                {
                                    product: this.product.id,
                                    options: options,
                                    qty: this.quantity,
                                    pair: pair,
                                    pairPrice: pairPrice,
                                    pairQuantity: this.pairQuantity,
                                },
                            ])
                        );
                    }
                    this.$swal({
                        text: "เพิ่มสินค้าสำเร็จ",
                        icon: "success",
                    });
                    this.quantity = 1;
                } else {
                    this.$swal({
                        text: "เลือกจำนวนสินค้า",
                        icon: "error",
                    });
                }
            } else {
                this.$swal({
                    text: "สินค้าหมด",
                    icon: "error",
                });
            }
        },
        fetchData(id) {
            axios
                .get(`/product/${id}?shop=${this.shop}`)
                .then((resp) => {
                    if (resp.data.status === "success") {
                        this.product = resp.data.data[0];
                        this.images = resp.data.images;
                        this.options =
                            resp.data.data[0].options[0].options !== "ไม่มี"
                                ? resp.data.data[0].options[0].options
                                : null;
                        this.getStock();
                    }
                })
                .catch((err) => {
                    if (err?.response) {
                        if (err?.response?.status !== 500) {
                            this.$swal({
                                text: err?.response?.data?.message
                                    ? err?.response?.data?.message
                                    : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่",
                                icon: "error",
                            });
                        }
                    }
                });
        },
        getStock() {
            this.quantity = 1;
            if (this.options && this.product.options) {
                let stock;
                stock = this.product.options.filter((item) => {
                    if (item.options === this.options) {
                        return item;
                    }
                });
                this.currStock = stock[0].instock;
            } else if (this.product.options[0].instock) {
                this.currStock = this.product.options[0].instock;
            } else {
                this.currStock = null;
            }
        },
        handleQuantityMinus() {
            if (this.currStock > 0) {
                this.quantity =
                    this.quantity > 1 ? this.quantity - 1 : this.quantity;
            } else {
                this.quantity = 1;
            }
        },
        handleQuantityPlus() {
            if (this.currStock > 0) {
                this.quantity = this.quantity + 1;
            } else {
                this.quantity = 1;
            }
        },
        getChoices(id) {
            if (document.getElementById(id)) {
                var element = document.getElementById(id);
                return new Choices(element, {
                    searchEnabled: false,
                    itemSelectText: "",
                    allowHTML: true,
                });
            }
        },
    },
    computed: {
        ...mapGetters(["Profile"]),
        calPrice() {
            let price =
                this.product.discount != 0
                    ? this.product.price - this.product.discount
                    : this.product.price;
            if (this.product.pair && this.pairSelect) {
                return (
                    (price +
                        this.product.pair[0].pair_price * this.pairQuantity) *
                    this.quantity
                );
            } else if (this.product.pair_price && this.pairSelect) {
                return (
                    (price + this.product.pair_price * this.pairQuantity) *
                    this.quantity
                );
            } else {
                return price * this.quantity;
            }
        },
        getDesc() {
            const doc = this.product.description;
            return doc.replace(/(\\r)*\\n/g, "<br>");
        },
    },
};
</script>

<style scoped>
.carousel-control-next,
.carousel-control-prev {
    filter: invert(85%);
}

.carousel-indicators [data-bs-target] {
    background-color: #939393 !important;
}

.carousel-indicators .active {
    background-color: #494747 !important;
}

.btn-options {
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    background: #fff;
    min-width: 5rem;
    min-height: 2.125rem;
    padding: 0.25rem 0.75rem;
    margin: 0 8px 8px 0;
    color: #333337;
    box-sizing: border-box;
    font-size: 12px;
}

.btn-options.active {
    border: 1px solid #2b2b2b;
    color: #2b2b2b;
}

.btn-options:disabled,
.btn-options[disabled] {
    border: 1px solid #efefef;
    color: #efefef;
}
</style>
