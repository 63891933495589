<template>
    <div class="pb-4 pt-0 container-fluid">
        <div class="row" v-if="boardcastData.length != 0">
            <div class="col-12">
                <div
                    class="d-flex justify-content-center align-items-center w-100"
                >
                    <div class="boardcast-warpper">
                        <swiper
                            class="swiper"
                            :modules="modules"
                            :slides-per-view="1"
                            :space-between="10"
                            :loop="true"
                            :autoplay="{
                                delay: 4000,
                                disableOnInteraction: false,
                            }"
                            :breakpoints="{
                                '830': {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                            }"
                        >
                            <swiper-slide v-for="(item, index) in boardcastData" :key="index"
                                ><img
                                    class="img-fluid"
                                    width="1200"
                                    height="590"
                                    :src="imagePath + item.img"
                                    :alt="`boardcast ${index}`"
                            /></swiper-slide>
                        </swiper>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3" v-if="boardcastProData.length != 0">
            <div class="col-12">
                <div
                    class="d-flex justify-content-center align-items-center w-100"
                >
                    <div class="boardcast-warpper">
                        <swiper
                            class="swiper"
                            :modules="modules"
                            :slides-per-view="2"
                            :space-between="10"
                            :loop="true"
                            :autoplay="{
                                delay: 4000,
                                disableOnInteraction: false,
                            }"
                            :breakpoints="{
                                '830': {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                            }"
                        >
                            <swiper-slide v-for="(item, index) in boardcastProData" :key="index"
                                ><img
                                    class="img-fluid"
                                    width="1200"
                                    height="590"
                                    :src="imagePath + item.img"
                                    :alt="`boardcast pro ${index}`"
                            /></swiper-slide>
                        </swiper>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import axios from "../services/axios";

export default {
    name: "BoardcastPage",
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Autoplay],
        };
    },
    data() {
        return {
            imagePath: process.env.VUE_APP_IMAGE_PATH,
            boardcastData: [],
            boardcastProData: [],
        };
    },
    created() {
        this.fetchBoardcastData();
        this.fetchBoardcastProData();
    },
    methods: {
        fetchBoardcastData() {
            axios.get("/boardcast")
            .then((resp) => {
                if (resp.data.status === "success") {
                    this.boardcastData = resp.data.data;
                }
            }).catch((err) => {
                if (err?.response) {
                    if (err?.response?.status !== 500) {
                        this.$swal({
                            text: err?.response?.data?.message
                                ? err?.response?.data?.message
                                : "ไม่สามารถโหลดข้อมูล Boardcast ได้ กรุณาลองใหม่",
                            icon: "error",
                        });
                    }
                }
            })
        },
        fetchBoardcastProData() {
            axios.get("/boardcast/pro")
            .then((resp) => {
                if (resp.data.status === "success") {
                    this.boardcastProData = resp.data.data;
                }
            }).catch((err) => {
                if (err?.response) {
                    if (err?.response?.status !== 500) {
                        this.$swal({
                            text: err?.response?.data?.message
                                ? err?.response?.data?.message
                                : "ไม่สามารถโหลดข้อมูล Boardcast ได้ กรุณาลองใหม่",
                            icon: "error",
                        });
                    }
                }
            })
        }
    },
};
</script>

<style scoped>
.boardcast-warpper {
    width: 100%;
}
.swiper img {
    border-radius: 15px;
}
</style>
